import { formatUnits } from "@ethersproject/units";
import {
  Box,
  Button,
  Container,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Loading from "../loading";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import {
  ToastNotify,
  useStakingContract,
  useTokenContract,
} from "../ConnectivityAss/hooks";
import TimerCountDown from "../Timer";
import { AppContext } from "../utils";

export default function DepositsTable() {
  const matches = useMediaQuery("(max-width:700px)");
  const { account, signer, connect } = useContext(AppContext);
  const [stakeDetails, setstakeDetails] = useState([]);
  const [loading, setloading] = useState(false);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const stakingContract = useStakingContract(signer);
  const tokenContract = useTokenContract(signer);
  let now = moment().format("x");

  const init = async () => {
    try {
      const dec = await tokenContract.decimals();
      const count = await stakingContract.getUserAmountOfDeposits(account);
      let arr = [];
      for (let i = 0; i < +count; i++) {
        const { plan, amount, profit, finish } =
          await stakingContract.getUserDepositInfo(account, +i);
        const active = await stakingContract.isDepositActive(account, +i);

        let obj = {
          plan: +plan,
          amount: +formatUnits(amount.toString(), +dec),
          profit: +formatUnits(profit.toString(), +dec) / 100,
          timeRemain: +finish,
          state: active,
        };
        arr.push(obj);
        setstakeDetails([...arr]);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (account) {
      init();
    }
  }, [account]);

  return (
    <Box py={5}>
      <Loading loading={loading} />
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Container maxWidth="xl">
        <Box
          style={{
            background:
              "linear-gradient(20deg, #997800, #d4a600 28%, #d4a600 50%, #997800 79%, #d4a600)",
          }}
          className="shadow"
          display="flex"
          alignItems="center"
          flexDirection="column"
          p={1}
        >
          <Box
            color="#ffffff"
            fontSize={matches ? "22px" : "40px"}
            fontWeight={700}
            fontFamily="Montserrat"
            borderBottom="8px solid #ffffff"
            pb={0.5}
          >
            YOUR STAKES
          </Box>

          <TableContainer
            style={{
              boxShadow: "1px 1px 20px 20px rgba(255, 255, 255, 0.1)",
              display: "flex",
              flexDirection: "column",
              background: "#ffffff",
            }}
            filter="blur(52px)"
            zIndex="10"
            pt={2}
            pb={5}
            mt={2}
            component={Box}
            color="#ffffff"
          >
            <Table
              aria-label="simple table"
              style={{
                marginTop: "0px",
              }}
              color="#000000"
            >
              <TableHead color="#000000">
                <TableRow style={{ color: "#000000" }}>
                  <TableCell
                    align="center"
                    style={{
                      color: "#000000",
                      fontSize: matches ? "14px" : "18px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    PLAN
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "#000000",
                      fontSize: matches ? "14px" : "18px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    AMOUNT{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "#000000",
                      fontSize: matches ? "14px" : "18px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    {" "}
                    PROFIT
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{
                      color: "#000000",
                      fontSize: matches ? "14px" : "18px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    TIME REMAINING
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "#000000",
                      fontSize: matches ? "14px" : "18px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    STATE
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stakeDetails.length > 0 ? (
                  stakeDetails.map(
                    ({ plan, amount, timeRemain, profit, state }, i) => (
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{
                            color: "#000000",
                            fontSize: matches ? "14px" : "18px",
                            fontFamily: "Montserrat",
                          }}
                        >
                          {plan == 0 ? "30 Days" : "45 Days"}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            color: "#000000",
                            fontSize: matches ? "14px" : "18px",
                            fontFamily: "Montserrat",
                          }}
                        >
                          {amount}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            color: "#000000",
                            fontSize: matches ? "14px" : "18px",
                            fontFamily: "Montserrat",
                          }}
                        >
                          {profit}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            color: "#000000",
                            fontSize: matches ? "14px" : "18px",
                            fontFamily: "Montserrat",
                          }}
                        >
                          <TimerCountDown time={timeRemain} />
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            color: "#000000",
                            fontSize: matches ? "14px" : "18px",
                            fontFamily: "Montserrat",
                          }}
                        >
                          {state ? "Active" : "Close"}
                        </TableCell>
                      </TableRow>
                    )
                  )
                ) : loading ? (
                  loading
                ) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{
                        color: "#000000",
                        fontSize: matches ? "14px" : "18px",
                        fontFamily: "Montserrat",
                        fontFamily: "Montserrat",
                      }}
                      colSpan={5}
                    >
                      You have no staking history yet
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Container>
    </Box>
  );
}
