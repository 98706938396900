import React, { useContext, useEffect, useState } from "react";
import Web3 from "web3";
import "./App.css";
import AboutUs from "./components/AboutUs";
import Dashboard from "./components/Dashboard";
import DepositsTable from "./components/DepositsTable";
import Footer from "./components/Footer";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import Info from "./components/Info";
import InvestCard from "./components/InvestCard";
import Links from "./components/Links";
import Referral from "./components/Referrals";
import NetworkChange from "./networkSwitch";
import { AppContext } from "./utils";
const web3 = new Web3(
  Web3.givenProvider ? Web3.givenProvider : "https://bsc-dataseed.binance.org/"
);
function App() {
  const [open, setOpen] = useState(false);
  const { account, connect } = useContext(AppContext);

  useEffect(() => {
    let chain = async () => {
      const chainid = await web3.eth.getChainId();
      if (chainid !== 56) {
        setOpen(true);
      }
    };
    chain();
    if (!account) {
      connect();
    }
  }, []);

  return (
    <>
      <NetworkChange open={open} setOpen={setOpen} />
      <Header />
      <Links />
      <HeroSection />
      <AboutUs />
      <Info />
      <InvestCard />
      <Dashboard />
      <DepositsTable />
      <Referral />
      <Footer />
    </>
  );
}

export default App;
