import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  InputBase,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import {
  ToastNotify,
  useStakingContract,
  useTokenContract,
} from "../ConnectivityAss/hooks";
import Loading from "../loading";
import { AppContext, gasEstimationForAll } from "../utils";
import { MaxUint256 } from "@ethersproject/constants";
import { defaultAddress, stakingAddress } from "../ConnectivityAss/environment";
import { formatUnits, parseUnits } from "@ethersproject/units";
import TimerCountDown from "../Timer";
import TimerNext from "./NextRewardTimer";

export default function InvestCard() {
  const matches = useMediaQuery("(max-width:700px)");
  const { account, signer, connect } = useContext(AppContext);
  const [referralAdd, setreferralAdd] = useState("");
  const [investedAmountZero, setinvestedAmountZero] = useState("");
  const [investedAmountOne, setinvestedAmountOne] = useState("");
  const [zeroPercentage, setzeroPercentage] = useState(0);
  const [zeroProfit, setzeroProfit] = useState(0);
  const [onePercentage, setonePercentage] = useState(0);
  const [oneProfit, setoneProfit] = useState(0);
  const [loading, setloading] = useState(false);
  const [userBalance, setuserBalance] = useState(0);
  const [userDividends, setuserDividends] = useState(0);
  const [nextRewardTime, setnextRewardTime] = useState(0);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const stakingContract = useStakingContract(signer);
  const tokenContract = useTokenContract(signer);

  useEffect(async () => {
    //Referrals
    if (window.location.href.includes("/?ref=")) {
      let getAddress = window.location.href.split("/?ref=")[1];
      let final = getAddress.slice(0, 42);
      localStorage.setItem("JTBIT", final);
      setreferralAdd(localStorage.getItem("JTBIT"));
    }
  }, []);
  const init = async () => {
    try {
      if (investedAmountZero > 0) {
        const { percent, profit } = await stakingContract.getResult(
          0,
          investedAmountZero
        );
        setzeroPercentage(formatUnits(+percent));
        setzeroProfit(formatUnits(+profit, 2));
      }
      if (investedAmountOne > 0) {
        const UserProfitResponse1 = await stakingContract.getResult(
          1,
          investedAmountOne
        );
        setonePercentage(formatUnits(+UserProfitResponse1.percent));
        setoneProfit(formatUnits(+UserProfitResponse1.profit, 2));
      }
    } catch (error) {}
  };
  useEffect(() => {
    init();
  }, [investedAmountZero, investedAmountOne]);

  const initUser = async () => {
    try {
      const dec = await tokenContract.decimals();
      const bal = await tokenContract.balanceOf(account);

      setuserBalance(formatUnits(bal.toString(), +dec));
      const checkpoint = await stakingContract.getUserCheckpoint(account);
      let timeReward = +checkpoint + 86400;
      setnextRewardTime(+timeReward);
      const dividends = await stakingContract.getUserDividends(account);
      setuserDividends(formatUnits(dividends.toString(), +dec));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (account) {
      initUser();
    }
  }, [account]);
  const investHadler = async (investedAmount, index) => {
    if (!account) {
      setAlertState({
        open: true,
        message: `Please connect your wallet`,
        severity: "error",
      });
    } else if (!investedAmount || +investedAmount <= 0) {
      setAlertState({
        open: true,
        message: `Please enter amount`,
        severity: "error",
      });
    } else {
      try {
        setloading(true);
        let allow = await tokenContract.allowance(account, stakingAddress);
        let dec = await tokenContract.decimals();
        if (+allow === 0) {
          let tx = await tokenContract.approve(
            stakingAddress,
            MaxUint256.toString()
          );
          await tx.wait();
        }
        let refAdd = referralAdd ? referralAdd : defaultAddress;
        const tx = await stakingContract.invest(
          refAdd,
          index,
          parseUnits(investedAmount.toString(), +dec).toString()
        );
        await tx.wait();
        setAlertState({
          open: true,
          message: `Success! Transaction Confirmed.`,
          severity: "success",
        });
        setloading(false);
        window.location.reload();
      } catch (error) {
        setloading(false);
        if (error?.data?.message) {
          setAlertState({
            open: true,
            message: error?.data?.message,
            severity: "error",
          });
        } else if (error?.reason) {
          setAlertState({
            open: true,
            message: error?.reason,
            severity: "error",
          });
        } else {
          setAlertState({
            open: true,
            message: error?.message,
            severity: "error",
          });
        }
      }
    }
  };
  const withdrawHandler = async () => {
    try {
      setloading(true);
      let data = [];
      let fn = stakingContract.estimateGas.withdraw;
      const tx = await stakingContract.withdraw({
        gasLimit: gasEstimationForAll(account, fn, data),
      });
      await tx.wait();
      setAlertState({
        open: true,
        message: `Success! Transaction Confirmed.`,
        severity: "success",
      });
      setloading(false);
      window.location.reload();
    } catch (error) {
      setloading(false);
      if (error?.data?.message) {
        setAlertState({
          open: true,
          message: error?.data?.message,
          severity: "error",
        });
      } else if (error?.reason) {
        setAlertState({
          open: true,
          message: error?.reason,
          severity: "error",
        });
      } else {
        setAlertState({
          open: true,
          message: error?.message,
          severity: "error",
        });
      }
    }
  };

  return (
    <Box py={5}>
      <Loading loading={loading} />
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box
              height="100%"
              sx={{
                background:
                  "linear-gradient(20deg, #997800, #d4a600 28%, #d4a600 50%, #997800 79%, #d4a600)",
                borderRadius: "0px",
              }}
              className="shadow"
              p={2}
            >
              <Box
                color="#ffffff"
                fontSize="22px"
                fontWeight={700}
                textAlign="left"
                width="100%"
                fontFamily="Montserrat"
                borderBottom="8px solid #ffffff"
                pb={0.5}
              >
                AVAILABLE BALANCE {parseFloat(userBalance).toFixed(2)}
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>
                  <Box
                    color="#ffffff"
                    fontSize="20px"
                    fontWeight={500}
                    fontFamily="Montserrat"
                    mt={2}
                  >
                    Your Earnings
                  </Box>
                  <Box
                    color="#ffffff"
                    fontSize="30px"
                    fontWeight={700}
                    fontFamily="Montserrat"
                  >
                    {parseFloat(userDividends).toFixed(2)}{" "}
                    <span style={{ fontSize: "14px", fontWeight: "400" }}>
                      JTBIT
                    </span>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box
                    color="#ffffff"
                    fontSize="20px"
                    fontWeight={500}
                    fontFamily="Montserrat"
                    mt={2}
                    textAlign="right"
                  >
                    Next Reward In
                  </Box>
                  <Box textAlign="right">
                    <TimerNext time={nextRewardTime} />
                  </Box>
                </Box>
              </Box>

              <Button
                sx={{
                  width: "192px",
                  height: "42px",
                  background:
                    "linear-gradient(20deg, #997800, #d4a600 28%, #d4a600 50%, #997800 79%, #d4a600)",
                  boxShadow: "0px 4px 16px rgba(62, 10, 13, 0.2)",
                  borderRadius: "3px",
                  mt: 2,
                }}
                onClick={() => withdrawHandler()}
              >
                <Typography
                  sx={{
                    lineHeight: "150%",
                  }}
                  fontWeight="700"
                  fontSize="12px"
                  display="flex"
                  align-items="center"
                  textAlign="center"
                  text-transform="uppercase"
                  color="#FFFFFF"
                  fontFamily="Montserrat"
                >
                  WITHDRAW
                </Typography>
              </Button>

              <Box
                color="#ffffff"
                fontSize="18px"
                fontWeight={500}
                fontFamily="Montserrat"
                mt={2}
              >
                Minimum Withdrawal is 1000 JTBIT
              </Box>
              <Box
                color="#ffffff"
                fontSize="18px"
                fontWeight={500}
                fontFamily="Montserrat"
                mt={0.5}
              >
                Maximum Withdrawal is 100,000 JTBIT
              </Box>
            </Box>
          </Grid>
          {/* <Grid item xs={12} md={4}>
            <Box height="100%" position="relative" borderRadius="10px">
              <Card
                sx={{
                  background:
                    "linear-gradient(20deg, #997800, #d4a600 28%, #d4a600 50%, #997800 79%, #d4a600)",
                  borderRadius: "0px",
                }}
                className="shadow"
              >
                <CardContent>
                  <Box display="flex" justifyContent="space-between">
                    <Box>
                      <Typography
                        gutterBottom
                        component="div"
                        sx={{
                          fontWeight: "700",
                          fontSize: matches ? "20px" : "25px",
                          fontFamily: "Montserrat",
                          display: "flex",
                          alignItems: "center",
                          color: "#FFFFFF",
                        }}
                      >
                        DEPOSIT{" "}
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems={"center"}>
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "10px",
                          lineHeight: "150%",

                          display: "flex",
                          alignItems: "center",
                          textAlign: "right",
                          textTransform: "uppercase",
                          fontFamily: "Montserrat",

                          color: "#FFFFFF",
                        }}
                      >
                        Unlocked Stake
                      </Typography>

                      <LockOpenIcon
                        sx={{
                          color: "#FFFFFF",
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="space-between" pt={2}>
                    <Box>
                      <Typography
                        fontWeight="700"
                        fontSize="13px"
                        lineHeight="150%"
                        display="flex"
                        alignItems="center"
                        textTransform="uppercase"
                        color="#DFDFDF"
                        fontFamily="Montserrat"
                      >
                        daily earnings:
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        fontWeight="700"
                        fontSize="13px"
                        lineHeight="150%"
                        display="flex"
                        alignItems="center"
                        textTransform="uppercase"
                        color="#DFDFDF"
                        fontFamily="Montserrat"
                      >
                        total roi:
                      </Typography>
                    </Box>
                  </Box>

                  <Box display="flex" justifyContent="space-between">
                    <Box>
                      <Typography
                        fontWeight="600"
                        fontSize={{ xs: "20px", md: "26px" }}
                        lineHeight="150%"
                        display="flex"
                        alignItems="center"
                        color="#FFFFFF"
                        fontFamily="Montserrat"
                      >
                        0.05%{" "}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        fontWeight="600"
                        fontSize={{ xs: "20px", md: "26px" }}
                        lineHeight="150%"
                        display="flex"
                        alignItems="center"
                        color="#FFFFFF"
                        fontFamily="Montserrat"
                      >
                        115%{" "}
                      </Typography>
                    </Box>
                  </Box>

                
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      boxShadow="#ffffff 0px 0px 7px 1px"
                      px={2}
                      mt={1}
                      borderRadius="50px"
                      bgcolor="#ffffff"
                    >
                      <Typography
                        fontWeight="600"
                        fontSize="50px"
                        lineHeight="150%"
                        textAlign="center"
                        style={{
                          backgroundImage:
                            "linear-gradient(45deg,#d4a600, #000000,#d4a600)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                        }}
                        fontFamily="Montserrat"
                      >
                        30
                      </Typography>

                      <Typography
                        fontWeight="600"
                        fontSize="20px"
                        ml={1}
                        textAlign="center"
                        color="#B18C8E"
                        fontFamily="Montserrat"
                      >
                        days
                      </Typography>
                    </Box>
                  </Box>
              
                  <Box
                    pt={1}
                    pl={1}
                    mt={2}
                    display="flex"
                    flexDirection="column"
                    style={{
                      backgroundColor: "#ffffff",
                      borderRadius: "0px",
                    }}
                    width="100%"
                    pb={1}
                  >
                    <Box
                      fontFamily="Montserrat"
                      fontSize="12px"
                      fontWeight="700"
                      color="#B18C8E"
                      style={{ opacity: "0.8" }}
                    >
                      Enter JTBIT Amount
                    </Box>
                    <InputBase
                      style={{
                        color: "#000000",
                        fontFamily: "Montserrat",
                        fontWeight: "700",
                        fontSize: "22px",
                        width: "100%",
                        paddingRight: "15px",
                        backgroundColor: "transparent",
                      }}
                      fullWidth
                      type="number"
                      id="standard-basic"
                      variant="standard"
                      placeholder="Enter Amount"
                      value={investedAmountZero}
                      onChange={(e) => {
                        setinvestedAmountZero(e.target.value);
                      }}
                    />
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent="center"
                    alignItems="center"
                    pt={2}
                  >
                    <Typography
                      fontWeight="700"
                      fontSize="13px"
                      lineHeight="150%"
                      display="flex"
                      alignItems="center"
                      color="#DFDFDF"
                      fontFamily="Montserrat"
                    >
                      ROI in{" "}
                      <span
                        style={{
                          fontFamily: "Montserrat",
                          marginLeft: "2px",
                          marginRight: "2px",
                        }}
                      >
                        {" "}
                        30{" "}
                      </span>{" "}
                      Days:
                    </Typography>
                    <Typography
                      fontWeight="600"
                      fontSize="20px"
                      lineHeight="150%"
                      alignItems="center"
                      marginLeft="5px"
                      color="#FFFFFF"
                      fontFamily="Montserrat"
                    >
                      {parseFloat(zeroProfit).toFixed(2)} JTBIT
                    </Typography>
                  </Box>
                  <Box
                    fontWeight="700"
                    fontSize="13px"
                    textAlign="center"
                    marginLeft="5px"
                    color="#DFDFDF"
                    fontFamily="Montserrat"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    Min Deposit :
                    <Typography
                      fontWeight="600"
                      fontSize="20px"
                      lineHeight="150%"
                      alignItems="center"
                      marginLeft="5px"
                      color="#FFFFFF"
                      fontFamily="Montserrat"
                    >
                      2000 JTBIT
                    </Typography>
                  </Box>
                  <Box
                    mt={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <Button
                      onClick={() => investHadler(investedAmountZero, 0)}
                      sx={{
                        width: "192px",
                        height: "42px",
                        background:
                          "linear-gradient(20deg, #997800, #d4a600 28%, #d4a600 50%, #997800 79%, #d4a600)",
                        boxShadow: "0px 4px 16px rgba(62, 10, 13, 0.2)",
                        borderRadius: "3px",
                      }}
                    >
                      <Typography
                        sx={{
                          lineHeight: "150%",
                        }}
                        fontWeight="700"
                        fontSize="12px"
                        display="flex"
                        align-items="center"
                        textAlign="center"
                        text-transform="uppercase"
                        color="#FFFFFF"
                        fontFamily="Montserrat"
                      >
                        deposit{" "}
                      </Typography>
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Grid> */}
          <Grid item xs={12} md={6}>
            <Box height="100%" position="relative" borderRadius="10px">
              <Card
                sx={{
                  background:
                    "linear-gradient(20deg, #997800, #d4a600 28%, #d4a600 50%, #997800 79%, #d4a600)",
                  borderRadius: "0px",
                }}
                className="shadow"
              >
                <CardContent>
                  <Box display="flex" justifyContent="space-between">
                    <Box>
                      <Typography
                        gutterBottom
                        component="div"
                        sx={{
                          fontWeight: "700",
                          fontSize: matches ? "20px" : "25px",
                          fontFamily: "Montserrat",
                          display: "flex",
                          alignItems: "center",
                          color: "#FFFFFF",
                        }}
                      >
                        DEPOSIT{" "}
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems={"center"}>
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "10px",
                          lineHeight: "150%",

                          display: "flex",
                          alignItems: "center",
                          textAlign: "right",
                          textTransform: "uppercase",
                          fontFamily: "Montserrat",

                          color: "#FFFFFF",
                        }}
                      >
                        Unlocked Stake
                      </Typography>

                      <LockOpenIcon
                        sx={{
                          color: "#FFFFFF",
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="space-between" pt={2}>
                    <Box>
                      <Typography
                        fontWeight="700"
                        fontSize="13px"
                        lineHeight="150%"
                        display="flex"
                        alignItems="center"
                        textTransform="uppercase"
                        color="#DFDFDF"
                        fontFamily="Montserrat"
                      >
                        daily earnings:
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        fontWeight="700"
                        fontSize="13px"
                        lineHeight="150%"
                        display="flex"
                        alignItems="center"
                        textTransform="uppercase"
                        color="#DFDFDF"
                        fontFamily="Montserrat"
                      >
                        total roi:
                      </Typography>
                    </Box>
                  </Box>

                  <Box display="flex" justifyContent="space-between">
                    <Box>
                      <Typography
                        fontWeight="600"
                        fontSize={{ xs: "20px", md: "26px" }}
                        lineHeight="150%"
                        display="flex"
                        alignItems="center"
                        color="#FFFFFF"
                        fontFamily="Montserrat"
                      >
                        1%{" "}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        fontWeight="600"
                        fontSize={{ xs: "20px", md: "26px" }}
                        lineHeight="150%"
                        display="flex"
                        alignItems="center"
                        color="#FFFFFF"
                        fontFamily="Montserrat"
                      >
                        365%{" "}
                      </Typography>
                    </Box>
                  </Box>

                  {/* Center------ */}
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      boxShadow="#ffffff 0px 0px 7px 1px"
                      px={2}
                      mt={1}
                      borderRadius="50px"
                      bgcolor="#ffffff"
                    >
                      <Typography
                        fontWeight="600"
                        fontSize="50px"
                        lineHeight="150%"
                        textAlign="center"
                        style={{
                          backgroundImage:
                            "linear-gradient(45deg,#d4a600, #000000,#d4a600)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                        }}
                        fontFamily="Montserrat"
                      >
                        365
                      </Typography>

                      <Typography
                        fontWeight="600"
                        fontSize="20px"
                        ml={1}
                        textAlign="center"
                        color="#B18C8E"
                        fontFamily="Montserrat"
                      >
                        days
                      </Typography>
                    </Box>
                  </Box>
                  {/* bLACK DIV */}
                  <Box
                    pt={1}
                    pl={1}
                    mt={2}
                    display="flex"
                    flexDirection="column"
                    style={{
                      backgroundColor: "#ffffff",
                      borderRadius: "0px",
                    }}
                    width="100%"
                    pb={1}
                  >
                    <Box
                      fontFamily="Montserrat"
                      fontSize="12px"
                      fontWeight="700"
                      color="#B18C8E"
                      style={{ opacity: "0.8" }}
                    >
                      Enter JTBIT Amount
                    </Box>
                    <InputBase
                      style={{
                        color: "#000000",
                        fontFamily: "Montserrat",
                        fontWeight: "700",
                        fontSize: "22px",
                        width: "100%",
                        paddingRight: "15px",
                        backgroundColor: "transparent",
                      }}
                      fullWidth
                      type="number"
                      id="standard-basic"
                      variant="standard"
                      placeholder="Enter Amount"
                      value={investedAmountOne}
                      onChange={(e) => {
                        setinvestedAmountOne(e.target.value);
                      }}
                    />
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent="center"
                    alignItems="center"
                    pt={2}
                  >
                    <Typography
                      fontWeight="700"
                      fontSize="13px"
                      lineHeight="150%"
                      display="flex"
                      alignItems="center"
                      color="#DFDFDF"
                      fontFamily="Montserrat"
                    >
                      ROI in{" "}
                      <span
                        style={{
                          fontFamily: "Montserrat",
                          marginLeft: "2px",
                          marginRight: "2px",
                        }}
                      >
                        {" "}
                        365{" "}
                      </span>{" "}
                      Days:
                    </Typography>
                    <Typography
                      fontWeight="600"
                      fontSize="20px"
                      lineHeight="150%"
                      alignItems="center"
                      marginLeft="5px"
                      color="#FFFFFF"
                      fontFamily="Montserrat"
                    >
                      {parseFloat(oneProfit).toFixed(2)} JTBIT
                    </Typography>
                  </Box>
                  <Box
                    fontWeight="700"
                    fontSize="13px"
                    textAlign="center"
                    marginLeft="5px"
                    color="#DFDFDF"
                    fontFamily="Montserrat"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    Min Deposit :
                    <Typography
                      fontWeight="600"
                      fontSize="20px"
                      lineHeight="150%"
                      alignItems="center"
                      marginLeft="5px"
                      color="#FFFFFF"
                      fontFamily="Montserrat"
                    >
                      2000 JTBIT
                    </Typography>
                  </Box>
                  <Box
                    mt={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <Button
                      onClick={() => investHadler(investedAmountOne, 0)}
                      sx={{
                        width: "192px",
                        height: "42px",
                        background:
                          "linear-gradient(20deg, #997800, #d4a600 28%, #d4a600 50%, #997800 79%, #d4a600)",
                        boxShadow: "0px 4px 16px rgba(62, 10, 13, 0.2)",
                        borderRadius: "3px",
                      }}
                    >
                      <Typography
                        sx={{
                          lineHeight: "150%",
                        }}
                        fontWeight="700"
                        fontSize="12px"
                        display="flex"
                        align-items="center"
                        textAlign="center"
                        text-transform="uppercase"
                        color="#FFFFFF"
                        fontFamily="Montserrat"
                      >
                        deposit{" "}
                      </Typography>
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
