import { Box, Container, Grid, useMediaQuery } from "@mui/material";
import React from "react";
import { useTheme } from "@emotion/react";

import logo from "../images/logo.png";

function Footer() {
  const matches = useMediaQuery("(max-width:750px)");
  const theme = useTheme();

  return (
    <>
      <Box position="relative" zIndex={1} pt={10} pb={5}>
        <Container maxWidth="xl">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={12} sm={9}>
              <Box
                display="flex"
                flexDirection={matches ? "column" : "row"}
                alignItems="center"
              >
                <Box
                  mr={matches ? 0 : 3}
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "700",
                    fontSize: "30px",
                    textAlign: "left",
                  }}
                  color="#fff"
                >
                  JTBIT ROI
                </Box>
                <Box
                  fontFamily="Montserrat"
                  fontSize={matches ? "13px" : "22px"}
                  fontWeight="600"
                  color="#fff"
                >
                  © Copyright <b>JTBIT</b>. All Rights Reserved
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent={matches ? "center" : "flex-end"}
                mt={matches ? 3 : 0}
              >
                <i
                  class="fab fa-twitter"
                  style={{
                    color: "#fff",
                    fontSize: "25px",
                    marginRight: "20px",
                  }}
                ></i>

                <i
                  class="fab fa-telegram-plane"
                  style={{
                    color: "#fff",
                    fontSize: "25px",
                    marginRight: "20px",
                  }}
                ></i>
                <i
                  class="fab fa-medium-m"
                  style={{
                    color: "#fff",
                    fontSize: "25px",
                    marginRight: "20px",
                  }}
                ></i>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default Footer;
