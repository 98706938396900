import React, { useContext } from "react";
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import { AppContext } from "../utils";
import logo from "../images/logo.png";

export default function Header() {
  const { account, connect, disconnect } = useContext(AppContext);
  const matches = useMediaQuery("(max-width:600px)");

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        style={{
          background: "transparent",
          zIndex: "100px",
        }}
        height="92px"
        width="100%"
        mt={1}
      >
        <Container maxWidth="xl">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pl={matches ? 0 : 5}
            pr={matches ? 0 : 5}
          >
            <Box
              style={{
                textDecoration: "none",
                cursor: "pointer",
                color: "#000000",
                fontSize: "20px",
              }}
            >
              <img width={matches ? "70px" : "90px"} src={logo} alt="" />
            </Box>

            {account ? (
              <Box
                width="130px"
                height="42px"
                borderRadius="0px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                color="#ffffff"
                fontWeight="500"
                className="shadow"
                fontSize="16px"
                fontFamily="Montserrat"
                onClick={() => disconnect()}
                style={{
                  zIndex: 1,
                  cursor: "pointer",
                  background:
                    "linear-gradient(20deg, #997800, #d4a600 28%, #d4a600 50%, #997800 79%, #d4a600)",
                }}
              >
                {account.slice(0, 4) + "..." + account.slice(-4)}
              </Box>
            ) : (
              <Box
                zIndex={1}
                style={{
                  cursor: "pointer",
                  background:
                    "linear-gradient(20deg, #997800, #d4a600 28%, #d4a600 50%, #997800 79%, #d4a600)",
                }}
                width="130px"
                height="42px"
                fontFamily="Montserrat"
                fontWeight="500"
                borderRadius="0px"
                className="shadow"
                fontSize="20px"
                color="#ffffff"
                display="flex"
                justifyContent="center"
                alignItems="center"
                onClick={() => connect()}
              >
                Connect
              </Box>
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
}
