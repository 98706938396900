import { Box, Container, Grid } from "@mui/material";
import React from "react";

export default function Info() {
  return (
    <Box py={5}>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              width="100%"
              style={{
                background:
                  "linear-gradient(20deg, #997800, #d4a600 28%, #d4a600 50%, #997800 79%, #d4a600)",
              }}
              className="shadow"
              height="100%"
              p={1}
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              <Box
                color="#ffffff"
                fontSize="22px"
                fontWeight={700}
                fontFamily="Montserrat"
                borderBottom="8px solid #ffffff"
                pb={0.5}
              >
                VERIFIED CONTRACT
              </Box>
              <Box
                mt={2}
                color="#ffffff"
                fontSize="17px"
                fontWeight={400}
                fontFamily="Montserrat"
                textAlign="center"
              >
                The code was tested with compatible compilers and simulate
                manually reviewed for all commonly known and specific
                vulnerabilities
              </Box>
            </Box>
          </Grid>
          {/* <Grid item xs={12} sm={6} md={3}>
            <Box
              width="100%"
              style={{
                background:
                  "linear-gradient(20deg, #997800, #d4a600 28%, #d4a600 50%, #997800 79%, #d4a600)",
              }}
              className="shadow"
              height="100%"
              p={1}
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              <Box
                color="#ffffff"
                fontSize="20px"
                fontWeight={700}
                fontFamily="Montserrat"
                borderBottom="8px solid #ffffff"
                pb={0.5}
              >
                0.5% PROFIT DAILY
              </Box>
              <Box
                mt={2}
                color="#ffffff"
                fontSize="17px"
                fontWeight={400}
                fontFamily="Montserrat"
                textAlign="center"
              >
                Get 0.5% rewards based on your active deposits everyday.
              </Box>
            </Box>
          </Grid> */}
          <Grid item xs={12} sm={6} md={4}>
            <Box
              width="100%"
              style={{
                background:
                  "linear-gradient(20deg, #997800, #d4a600 28%, #d4a600 50%, #997800 79%, #d4a600)",
              }}
              className="shadow"
              height="100%"
              p={1}
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              <Box
                color="#ffffff"
                fontSize="22px"
                fontWeight={700}
                fontFamily="Montserrat"
                borderBottom="8px solid #ffffff"
                pb={0.5}
              >
                1% PROFIT DAILY
              </Box>
              <Box
                mt={2}
                color="#ffffff"
                fontSize="17px"
                fontWeight={400}
                fontFamily="Montserrat"
                textAlign="center"
              >
                Get 1% every 24 hours. Rewards based on your active deposits
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              width="100%"
              style={{
                background:
                  "linear-gradient(20deg, #997800, #d4a600 28%, #d4a600 50%, #997800 79%, #d4a600)",
              }}
              className="shadow"
              height="100%"
              p={1}
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              <Box
                color="#ffffff"
                fontSize="22px"
                fontWeight={700}
                fontFamily="Montserrat"
                borderBottom="8px solid #ffffff"
                pb={0.5}
              >
                ANTI-WHALE FEATURES
              </Box>
              <Box
                mt={2}
                color="#ffffff"
                fontSize="17px"
                fontWeight={400}
                fontFamily="Montserrat"
                textAlign="center"
              >
                Withdraw once a day,Minimum Withdrawal is 1000 JTBIT and Maximum
                Withdrawal is 100,000 JTBIT JTBIT at once! Automatic reinvest
                30% of withdrawal when balance decreasing guard line!
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
