import { formatUnits } from "@ethersproject/units";
import { Box, Container, Grid, useMediaQuery } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useStakingContract, useTokenContract } from "../ConnectivityAss/hooks";
import { AppContext } from "../utils";

export default function Dashboard() {
  const matches = useMediaQuery("(max-width:700px)");
  const { account, signer, connect } = useContext(AppContext);
  const [totalDeposits, settotalDeposits] = useState(0);
  const [contractBalance, setcontractBalance] = useState(0);
  const [totalReferralBonus, settotalReferralBonus] = useState(0);
  const [totalWithdrawn, settotalWithdrawn] = useState(0);
  const [activeDeposits, setactiveDeposits] = useState(0);
  const [availableForWithdraw, setavailableForWithdraw] = useState(0);

  const stakingContract = useStakingContract(signer);
  const tokenContract = useTokenContract(signer);
  const init = async () => {
    try {
      const dec = await tokenContract.decimals();
      const deposit = await stakingContract.getUserTotalDeposits(account);
      settotalDeposits(formatUnits(deposit.toString(), +dec));
      const withdrawn = await stakingContract.getUserTotalWithdrawn(account);

      settotalWithdrawn(formatUnits(withdrawn.toString(), +dec));
      const refBonus = await stakingContract.getUserReferralTotalBonus(account);
      settotalReferralBonus(formatUnits(refBonus.toString(), +dec));
      const available = await stakingContract.getUserAvailable(account);
      setavailableForWithdraw(formatUnits(available.toString(), +dec));
      const count = await stakingContract.getUserAmountOfDeposits(account);
      let amount = 0;
      for (let i = 0; i < +count; i++) {
        const isActive = await stakingContract.isDepositActive(account, +i);
        if (isActive) {
          amount += 1;
        }
        setactiveDeposits(+amount);
      }
    } catch (error) {
      console.log(error, "Dashboard");
    }
  };
  useEffect(() => {
    if (account) {
      init();
    }
  }, [account]);
  return (
    <Box py={5}>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box
              width="100%"
              style={{
                background:
                  "linear-gradient(20deg, #997800, #d4a600 28%, #d4a600 50%, #997800 79%, #d4a600)",
              }}
              className="shadow"
              height="100%"
              p={1}
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              <Box
                color="#ffffff"
                fontSize={matches ? "22px" : "40px"}
                fontWeight={700}
                fontFamily="Montserrat"
                borderBottom="8px solid #ffffff"
                pb={0.5}
              >
                YOUR STATISTICS
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexDirection={matches ? "column" : "row"}
                width="100%"
                mt={5}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  pb={1}
                  sx={{
                    borderRadius: "0px",
                    border: "1px transparent",
                    backgroundImage:
                      " linear-gradient(#ffffff, #ffffff  ),linear-gradient(20deg, #000000, #d4a600 28%, #d4a600 50%, #000000 79%, #d4a600)",
                    backgroundOrigin: "border-box",
                    backgroundClip: "content-box, border-box",
                  }}
                  width={matches ? "100%" : "18%"}
                >
                  <Box
                    color="#000000"
                    fontSize="20px"
                    fontWeight={500}
                    fontFamily="Montserrat"
                  >
                    Active Deposit
                  </Box>
                  <Box
                    color="#000000"
                    fontSize="30px"
                    fontWeight={700}
                    fontFamily="Montserrat"
                  >
                    {activeDeposits}
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  pb={1}
                  mt={matches ? 2 : 0}
                  sx={{
                    borderRadius: "0px",
                    border: "1px transparent",
                    backgroundImage:
                      " linear-gradient(#ffffff, #ffffff  ),linear-gradient(20deg, #000000, #d4a600 28%, #d4a600 50%, #000000 79%, #d4a600)",
                    backgroundOrigin: "border-box",
                    backgroundClip: "content-box, border-box",
                  }}
                  width={matches ? "100%" : "18%"}
                >
                  <Box
                    color="#000000"
                    fontSize="20px"
                    fontWeight={500}
                    fontFamily="Montserrat"
                  >
                    Available Withdrawal
                  </Box>
                  <Box
                    color="#000000"
                    fontSize="30px"
                    fontWeight={700}
                    fontFamily="Montserrat"
                  >
                    {parseFloat(availableForWithdraw).toFixed(2)}
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  pb={1}
                  sx={{
                    borderRadius: "0px",
                    border: "1px transparent",
                    backgroundImage:
                      " linear-gradient(#ffffff, #ffffff  ),linear-gradient(20deg, #000000, #d4a600 28%, #d4a600 50%, #000000 79%, #d4a600)",
                    backgroundOrigin: "border-box",
                    backgroundClip: "content-box, border-box",
                  }}
                  width={matches ? "100%" : "18%"}
                  mt={matches ? 2 : 0}
                >
                  <Box
                    color="#000000"
                    fontSize="20px"
                    fontWeight={500}
                    fontFamily="Montserrat"
                  >
                    Total Deposits
                  </Box>
                  <Box
                    color="#000000"
                    fontSize="30px"
                    fontWeight={700}
                    fontFamily="Montserrat"
                  >
                    {parseFloat(totalDeposits).toFixed(2)}
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  pb={1}
                  sx={{
                    borderRadius: "0px",
                    border: "1px transparent",
                    backgroundImage:
                      " linear-gradient(#ffffff, #ffffff  ),linear-gradient(20deg, #000000, #d4a600 28%, #d4a600 50%, #000000 79%, #d4a600)",
                    backgroundOrigin: "border-box",
                    backgroundClip: "content-box, border-box",
                  }}
                  width={matches ? "100%" : "18%"}
                  mt={matches ? 2 : 0}
                >
                  <Box
                    color="#000000"
                    fontSize="20px"
                    fontWeight={500}
                    fontFamily="Montserrat"
                  >
                    Total Referral Bonus
                  </Box>
                  <Box
                    color="#000000"
                    fontSize="30px"
                    fontWeight={700}
                    fontFamily="Montserrat"
                  >
                    {parseFloat(totalReferralBonus).toFixed(2)}
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  pb={1}
                  sx={{
                    borderRadius: "0px",
                    border: "1px transparent",
                    backgroundImage:
                      " linear-gradient(#ffffff, #ffffff  ),linear-gradient(20deg, #000000, #d4a600 28%, #d4a600 50%, #000000 79%, #d4a600)",
                    backgroundOrigin: "border-box",
                    backgroundClip: "content-box, border-box",
                  }}
                  width={matches ? "100%" : "18%"}
                  mt={matches ? 2 : 0}
                >
                  <Box
                    color="#000000"
                    fontSize="20px"
                    fontWeight={500}
                    fontFamily="Montserrat"
                  >
                    Total Withdraw
                  </Box>
                  <Box
                    color="#000000"
                    fontSize="30px"
                    fontWeight={700}
                    fontFamily="Montserrat"
                  >
                    {parseFloat(totalWithdrawn).toFixed(2)}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
