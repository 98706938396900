import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Box, useMediaQuery } from "@mui/material";

export default function TimerNext({ time }) {
  const matches = useMediaQuery("(max-width:700px)");

  let interval = useRef();
  const [countTime, setCountDateTime] = useState({
    time_days: 0,
    time_Hours: 0,
    time_Minusts: 0,
    time_seconds: 0,
  });
  const startTime = async () => {
    let until = moment.unix(time).format("x");
    let interval = setInterval(() => {
      let now = moment().format("x");
      const distance = +until - +now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minuts = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (distance < 0) {
        clearInterval(interval.current);
      } else {
        setCountDateTime({
          ...countTime,
          time_days: days,
          time_Hours: hours,
          time_Minusts: minuts,
          time_seconds: seconds,
        });
      }
    }, 1000);
  };
  useEffect(() => {
    startTime();
  }, [time]);
  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box
          mr={matches ? 1 : 1}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box
            width={matches ? "30px" : "30px"}
            px={1}
            py={1}
            fontSize={matches ? "16px" : "20px"}
            fontWeight="600"
            textAlign="center"
            fontFamily="Montserrat"
          >
            {countTime.time_days}
          </Box>
        </Box>
        <Box fontSize="20px">:</Box>
        <Box
          mr={matches ? 1 : 1}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box
            width={matches ? "30px" : "30px"}
            px={1}
            py={1}
            fontSize={matches ? "16px" : "20px"}
            fontWeight="600"
            textAlign="center"
            fontFamily="Montserrat"
          >
            {countTime.time_Hours}
          </Box>
        </Box>
        <Box fontSize="20px">:</Box>
        <Box
          mr={matches ? 1 : 1}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box
            width={matches ? "30px" : "30px"}
            px={1}
            py={1}
            fontSize={matches ? "16px" : "20px"}
            fontWeight="600"
            textAlign="center"
            fontFamily="Montserrat"
          >
            {countTime.time_Minusts}
          </Box>
        </Box>
        <Box fontSize="20px">:</Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box
            width={matches ? "30px" : "30px"}
            px={1}
            py={1}
            fontSize={matches ? "16px" : "20px"}
            fontWeight="600"
            textAlign="center"
            fontFamily="Montserrat"
          >
            {countTime.time_seconds}
          </Box>
        </Box>
      </Box>
    </>
  );
}
