import { Box, Container, Grid } from "@mui/material";
import React from "react";
import TelegramIcon from "@mui/icons-material/Telegram";
import TwitterIcon from "@mui/icons-material/Twitter";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import LinkIcon from "@mui/icons-material/Link";
import bnb from "../images/bnb.svg";
import logo from "../images/logo.png";
import chart from "../images/chart.png";

const StyleBox = ({ children }) => {
  return (
    <Box
      sx={{
        borderRadius: "0px",
        border: "1px transparent",
        backgroundImage:
          " linear-gradient(#ffffff, #ffffff  ),linear-gradient(20deg, #997800, #d4a600 28%, #d4a600 50%, #997800 79%, #d4a600)",
        backgroundOrigin: "border-box",
        backgroundClip: "content-box, border-box",
        height: "100px",
        display: "flex",
        fontFamily: "Montserrat",
        fontSize: "28px",
        fontWeight: "700",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0 2px 6px 0 rgb(0 0 0 / 8%)",
      }}
      p={1}
    >
      {children}
    </Box>
  );
};
export default function Links() {
  return (
    <Box py={5}>
      <Container maxWidth="xl">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={4} md={3}>
            <Box className="shadow">
              <a
                style={{ textDecoration: "none", color: "#000000" }}
                href="https://youtu.be/wUeY3jsF5Kk"
                target="_blank"
              >
                <StyleBox>
                  <PlayCircleIcon
                    style={{
                      marginRight: "10px",
                      color: "#F0B90B",
                      fontSize: "40px",
                    }}
                  />
                  PLAY VIDEO
                </StyleBox>
              </a>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Box className="shadow">
              <a
                style={{ textDecoration: "none", color: "#000000" }}
                href="https://twitter.com/JTBITToken"
                target="_blank"
              >
                <StyleBox>
                  <TwitterIcon
                    style={{
                      marginRight: "10px",
                      color: "#F0B90B",
                      fontSize: "40px",
                    }}
                  />
                  TWITTER
                </StyleBox>
              </a>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <Box className="shadow">
              <a
                style={{ textDecoration: "none", color: "#000000" }}
                href="https://t.me/JTBITAirdropBot"
                target="_blank"
              >
                <StyleBox>
                  <TelegramIcon
                    style={{
                      marginRight: "10px",
                      color: "#F0B90B",
                      fontSize: "40px",
                    }}
                  />
                  TELEGRAM
                </StyleBox>
              </a>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Box className="shadow">
              <a
                style={{ textDecoration: "none", color: "#000000" }}
                href="/whitepaper.pdf"
                target="_blank"
              >
                <StyleBox>
                  <MenuBookIcon
                    fontSize="40px"
                    style={{
                      marginRight: "10px",
                      color: "#F0B90B",
                      fontSize: "40px",
                    }}
                  />
                  WHITEPAPER
                </StyleBox>
              </a>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Box className="shadow">
              <a
                style={{ textDecoration: "none", color: "#000000" }}
                href="https://bscscan.com/address/0x499d9ae50c629f71EfD1d0EC6D2142FCCb42098e#code"
                target="_blank"
              >
                <StyleBox>
                  <img
                    src={bnb}
                    alt=""
                    width="35px"
                    style={{ marginRight: "10px" }}
                  />
                  CONTRACT
                </StyleBox>
              </a>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Box className="shadow">
              <a
                style={{ textDecoration: "none", color: "#000000" }}
                href="https://pancakeswap.finance/swap?outputCurrency=0x499d9ae50c629f71EfD1d0EC6D2142FCCb42098e"
                target="_blank"
              >
                <StyleBox>
                  <img
                    src={logo}
                    alt=""
                    width="35px"
                    style={{ marginRight: "10px" }}
                  />
                  BUY JTBIT
                </StyleBox>
              </a>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Box className="shadow">
              <a
                style={{ textDecoration: "none", color: "#000000" }}
                href="https://chipstars.bet/?affiliate=8794"
                target="_blank"
              >
                <StyleBox>
                  <LinkIcon
                    fontSize="40px"
                    style={{
                      marginRight: "10px",
                      color: "#F0B90B",
                      fontSize: "40px",
                    }}
                  />
                  CASINO
                </StyleBox>
              </a>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Box className="shadow">
              <a
                style={{ textDecoration: "none", color: "#000000" }}
                href="https://poocoin.app/tokens/0x499d9ae50c629f71efd1d0ec6d2142fccb42098e"
                target="_blank"
              >
                <StyleBox>
                  <img
                    src={chart}
                    alt=""
                    width="35px"
                    style={{ marginRight: "10px" }}
                  />
                  LIVE CHART
                </StyleBox>
              </a>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
