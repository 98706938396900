import { Box, Container, Grid, useMediaQuery } from "@mui/material";
import React from "react";

export default function AboutUs() {
  const matches = useMediaQuery("(max-width:700px)");

  return (
    <Box py={5}>
      <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={12}>
            <Box
              width="100%"
              style={{
                background:
                  "linear-gradient(20deg, #997800, #d4a600 28%, #d4a600 50%, #997800 79%, #d4a600)",
              }}
              className="shadow"
              height="100%"
              p={1}
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              <Box
                color="#ffffff"
                fontSize={matches ? "22px" : "40px"}
                fontWeight={700}
                fontFamily="Montserrat"
                borderBottom="8px solid #ffffff"
                pb={0.5}
              >
                ABOUT US
              </Box>
              <Box
                mt={2}
                color="#ffffff"
                fontSize="17px"
                fontWeight={400}
                fontFamily="Montserrat"
                textAlign="center"
              >
                Founded by a team of entrepreneurs with a shared passion for
                online gaming and crypto staking, JTBIT offers steady rewards
                and an unrivaled gaming experience for all. Everyone is welcome
                to come and play with the rewards distributed fairly to all
                players via smart contracts. Get ready to play and earn while
                you enjoy the thrill of the metaverse!
              </Box>
              <Box
                color="#ffffff"
                fontSize="20px"
                fontWeight={500}
                fontFamily="Montserrat"
                my={2}
              >
                Why JTBIT Token?
              </Box>
              <Box
                color="#ffffff"
                fontSize="17px"
                fontWeight={400}
                fontFamily="Montserrat"
                textAlign="center"
              >
                JTBIT is our native currency that you earn for playing
                wager-based casinos such as Blackjack, Roulette, Bacarrat,
                Poker, and more. Stake your JTBIT tokens and receive up to 85%
                in staking rewards. Users can also earn JTBIT by referring
                others to play our games. JTBIT is built on BNB Chain to offer
                users lower transaction costs, speed and security, with
                near-instant deposits and withdrawals.
              </Box>
              <Box
                color="#ffffff"
                fontSize="20px"
                fontWeight={500}
                fontFamily="Montserrat"
                my={2}
              >
                Stake and Earn
              </Box>
              <Box
                color="#ffffff"
                fontSize="17px"
                fontWeight={400}
                fontFamily="Montserrat"
                textAlign="center"
              >
                Get up to 85% on your staked tokens. Keep your deposits in your
                wallet to collect interest every day, paid out in $JTBIT tokens.
              </Box>
              <Box
                color="#ffffff"
                fontSize="20px"
                fontWeight={500}
                fontFamily="Montserrat"
                my={2}
              >
                Mission
              </Box>
              <Box
                color="#ffffff"
                fontSize="17px"
                fontWeight={400}
                fontFamily="Montserrat"
                textAlign="center"
              >
                We are on a mission to create a unique and rewarding experience
                for our users through online gaming and reliable staking rewards
                program.
              </Box>
              <Box
                color="#ffffff"
                fontSize="20px"
                fontWeight={500}
                fontFamily="Montserrat"
                my={2}
              >
                Vision
              </Box>
              <Box
                color="#ffffff"
                fontSize="17px"
                fontWeight={400}
                fontFamily="Montserrat"
                textAlign="center"
              >
                By combing yield-bearing strategies like staking with a
                rewarding casino experience, we can empower everyone in the
                world to create sustainable passive income streams.
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
