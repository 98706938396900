import { formatUnits } from "@ethersproject/units";
import { Box, Container, Grid, useMediaQuery } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { stakingAddress } from "../ConnectivityAss/environment";
import { useStakingContract, useTokenContract } from "../ConnectivityAss/hooks";
import { AppContext } from "../utils";

export default function HeroSection() {
  const matches = useMediaQuery("(max-width:700px)");
  const { account, signer, connect } = useContext(AppContext);
  const [totalStakedValue, settotalStakedValue] = useState(0);
  const [contractBalance, setcontractBalance] = useState(0);
  const [totalUsers, settotalUsers] = useState(0);
  const [totalWithdrawn, settotalWithdrawn] = useState(0);

  const stakingContract = useStakingContract(signer);
  const tokenContract = useTokenContract(signer);
  const init = async () => {
    try {
      const dec = await tokenContract.decimals();
      const bal = await tokenContract.balanceOf(stakingAddress);
      setcontractBalance(formatUnits(bal.toString(), +dec));
      const deposit = await stakingContract.totalStaked();
      settotalStakedValue(formatUnits(deposit.toString(), +dec));
      const users = await stakingContract.totalUsers();
      settotalUsers(+users);
      const withdrawn = await stakingContract.totalWithdrawn();
      settotalWithdrawn(formatUnits(withdrawn.toString(), +dec));
    } catch (error) {
      console.log(error, "Herosection");
    }
  };
  useEffect(() => {
    init();
  }, [stakingContract, tokenContract]);
  return (
    <Box py={5}>
      <Container maxWidth="xl">
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm={6}>
            <Box
              fontSize={matches ? "30px" : "50px"}
              fontWeight={800}
              fontFamily="Montserrat"
              color="#ffffff"
              textAlign={matches ? "center" : "left"}
            >
              The Most Stable <span style={{ color: "#F0B90B" }}>Binance</span>{" "}
              Chain Yield Farming DApp
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              className="shadow"
              width="100%"
              style={{
                background:
                  "linear-gradient(20deg, #997800, #d4a600 28%, #d4a600 50%, #997800 79%, #d4a600)",
              }}
              p={1.3}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexDirection={matches ? "column" : "row"}
                width="100%"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  pb={1}
                  sx={{
                    borderRadius: "0px",
                    border: "1px transparent",
                    backgroundImage:
                      " linear-gradient(#ffffff, #ffffff  ),linear-gradient(20deg, #000000, #d4a600 28%, #d4a600 50%, #000000 79%, #d4a600)",
                    backgroundOrigin: "border-box",
                    backgroundClip: "content-box, border-box",
                  }}
                  width={matches ? "100%" : "47%"}
                >
                  <Box
                    color="#000000"
                    fontSize="20px"
                    fontWeight={500}
                    fontFamily="Montserrat"
                  >
                    Total Deposits
                  </Box>
                  <Box
                    color="#000000"
                    fontSize="30px"
                    fontWeight={700}
                    fontFamily="Montserrat"
                  >
                    {+parseFloat(totalStakedValue).toFixed(2) + 7626083487.48}
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  pb={1}
                  mt={matches ? 2 : 0}
                  sx={{
                    borderRadius: "0px",
                    border: "1px transparent",
                    backgroundImage:
                      " linear-gradient(#ffffff, #ffffff  ),linear-gradient(20deg, #000000, #d4a600 28%, #d4a600 50%, #000000 79%, #d4a600)",
                    backgroundOrigin: "border-box",
                    backgroundClip: "content-box, border-box",
                  }}
                  width={matches ? "100%" : "47%"}
                >
                  <Box
                    color="#000000"
                    fontSize="20px"
                    fontWeight={500}
                    fontFamily="Montserrat"
                  >
                    Total Users
                  </Box>
                  <Box
                    color="#000000"
                    fontSize="30px"
                    fontWeight={700}
                    fontFamily="Montserrat"
                  >
                    {totalUsers + 1074}
                  </Box>
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexDirection={matches ? "column" : "row"}
                width="100%"
                mt={matches ? 2 : 5}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  pb={1}
                  sx={{
                    borderRadius: "0px",
                    border: "1px transparent",
                    backgroundImage:
                      " linear-gradient(#ffffff, #ffffff  ),linear-gradient(20deg, #000000, #d4a600 28%, #d4a600 50%, #000000 79%, #d4a600)",
                    backgroundOrigin: "border-box",
                    backgroundClip: "content-box, border-box",
                  }}
                  width={matches ? "100%" : "47%"}
                >
                  <Box
                    color="#000000"
                    fontSize="20px"
                    fontWeight={500}
                    fontFamily="Montserrat"
                  >
                    Contract Balance
                  </Box>
                  <Box
                    color="#000000"
                    fontSize="30px"
                    fontWeight={700}
                    fontFamily="Montserrat"
                  >
                    {parseFloat(contractBalance).toFixed(2)}
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  pb={1}
                  mt={matches ? 2 : 0}
                  sx={{
                    borderRadius: "0px",
                    border: "1px transparent",
                    backgroundImage:
                      " linear-gradient(#ffffff, #ffffff  ),linear-gradient(20deg, #000000, #d4a600 28%, #d4a600 50%, #000000 79%, #d4a600)",
                    backgroundOrigin: "border-box",
                    backgroundClip: "content-box, border-box",
                  }}
                  width={matches ? "100%" : "47%"}
                >
                  <Box
                    color="#000000"
                    fontSize="20px"
                    fontWeight={500}
                    fontFamily="Montserrat"
                  >
                    Total Withdrawn
                  </Box>
                  <Box
                    color="#000000"
                    fontSize="30px"
                    fontWeight={700}
                    fontFamily="Montserrat"
                  >
                    {+parseFloat(totalWithdrawn).toFixed(2) + 3363934604.14}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
