import { Box, Container, Typography, Grid, Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useMediaQuery } from "@mui/material";
import CopyToClipboard from "react-copy-to-clipboard";
import { AppContext } from "../utils";
import { toast } from "react-toastify";
import { ToastNotify, useStakingContract } from "../ConnectivityAss/hooks";

export default function Referral() {
  const { account, signer } = useContext(AppContext);
  const matches = useMediaQuery("(max-width:700px)");
  const stakingContract = useStakingContract(signer);
  const [level1Users, setlevel1Users] = useState(0);
  const [level2Users, setlevel2Users] = useState(0);
  const [level3Users, setlevel3Users] = useState(0);
  const [level4Users, setlevel4Users] = useState(0);
  const [level5Users, setlevel5Users] = useState(0);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const init = async () => {
    try {
      const { level1, level2, level3, level4, level5 } =
        await stakingContract.getUserDownlineCount(account);
      setlevel1Users(+level1);
      setlevel2Users(+level2);
      setlevel3Users(+level3);
      setlevel4Users(+level4);
      setlevel5Users(+level5);
    } catch (error) {}
  };
  useEffect(() => {
    if (account) {
      init();
    }
  }, [account]);
  return (
    <Box position="relative" zIndex={1} py={5}>
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Container maxWidth="xl">
        <Box
          pt={3}
          pb={2}
          px={3}
          sx={{
            background:
              "linear-gradient(20deg, #997800, #d4a600 28%, #d4a600 50%, #997800 79%, #d4a600)",
          }}
          className="shadow"
          position="relative"
        >
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            pt={{ xs: 1, md: "0" }}
          >
            <Box
              color="#ffffff"
              fontSize="22px"
              fontWeight={700}
              textAlign="center"
              fontFamily="Montserrat"
              pb={0.5}
              mb={matches ? 0 : 2}
              borderBottom="8px solid #ffffff"
            >
              REFERRAL PROGRAM
            </Box>
          </Box>
          <Grid container spacing={matches ? 1 : 5} justifyContent="center">
            <Grid item xs={12} md={4}>
              <Box
                height="100%"
                position="relative"
                display="flex"
                alignItems="center"
                flexDirection="column"
                justifyContent="space-between"
              >
                {matches ? (
                  <Box mt={2} textAlign="center">
                    <Typography
                      fontWeight="700"
                      fontSize={{ xs: "12px", md: "15px" }}
                      textAlign="center"
                      color="#FFFFFF"
                      fontFamily="Montserrat"
                    >
                      Level 1 - 10%
                    </Typography>
                    <Typography
                      fontWeight="700"
                      fontSize={{ xs: "12px", md: "15px" }}
                      textAlign="center"
                      color="#FFFFFF"
                      fontFamily="Montserrat"
                    >
                      Level 2 - 5%
                    </Typography>
                    <Typography
                      fontWeight="700"
                      fontSize={{ xs: "12px", md: "14px" }}
                      textAlign="center"
                      color="#FFFFFF"
                      fontFamily="Montserrat"
                    >
                      Level 3 - 3%
                    </Typography>
                    <Typography
                      fontWeight="700"
                      fontSize={{ xs: "12px", md: "14px" }}
                      textAlign="center"
                      color="#FFFFFF"
                      fontFamily="Montserrat"
                    >
                      Level 4 - 1%
                    </Typography>
                    <Typography
                      fontWeight="700"
                      fontSize={{ xs: "12px", md: "14px" }}
                      textAlign="center"
                      color="#FFFFFF"
                      fontFamily="Montserrat"
                    >
                      Level 5 - 1%
                    </Typography>
                  </Box>
                ) : null}
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  boxShadow="#ffffff 0px 0px 7px 1px"
                  px={2}
                  mt={matches ? 2 : 1}
                >
                  <Typography
                    fontWeight="700"
                    fontSize="13px"
                    display="flex"
                    alignItems="center"
                    textTransform="uppercase"
                    color="#DFDFDF"
                    fontFamily="Montserrat"
                    marginTop="15px"
                  >
                    Level{" "}
                    <span
                      style={{ fontFamily: "Montserrat", marginLeft: "5px" }}
                    >
                      1
                    </span>
                  </Typography>

                  <Typography
                    fontWeight="600"
                    fontSize={{ xs: "20px", md: "26px" }}
                    lineHeight="150%"
                    display="flex"
                    alignItems="center"
                    color="#FFFFFF"
                    fontFamily="Montserrat"
                  >
                    {level1Users}
                  </Typography>
                </Box>
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  boxShadow="#ffffff 0px 0px 7px 1px"
                  px={2}
                  mt={1}
                >
                  <Typography
                    fontWeight="700"
                    fontSize="13px"
                    display="flex"
                    alignItems="center"
                    textTransform="uppercase"
                    color="#DFDFDF"
                    fontFamily="Montserrat"
                    marginTop="15px"
                  >
                    Level{" "}
                    <span
                      style={{ fontFamily: "Montserrat", marginLeft: "5px" }}
                    >
                      2
                    </span>
                  </Typography>

                  <Typography
                    fontWeight="600"
                    fontSize={{ xs: "20px", md: "26px" }}
                    lineHeight="150%"
                    display="flex"
                    alignItems="center"
                    color="#FFFFFF"
                    fontFamily="Montserrat"
                  >
                    {level2Users}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                height="100%"
                position="relative"
                display="flex"
                alignItems="center"
                flexDirection="column"
              >
                {matches ? null : (
                  <Box textAlign="center">
                    <Typography
                      fontWeight="700"
                      fontSize={{ xs: "12px", md: "15px" }}
                      textAlign="center"
                      color="#FFFFFF"
                      fontFamily="Montserrat"
                    >
                      Level 1 - 10%
                    </Typography>
                    <Typography
                      fontWeight="700"
                      fontSize={{ xs: "12px", md: "15px" }}
                      textAlign="center"
                      color="#FFFFFF"
                      fontFamily="Montserrat"
                    >
                      Level 2 - 5%
                    </Typography>
                    <Typography
                      fontWeight="700"
                      fontSize={{ xs: "12px", md: "14px" }}
                      textAlign="center"
                      color="#FFFFFF"
                      fontFamily="Montserrat"
                    >
                      Level 3 - 3%
                    </Typography>
                    <Typography
                      fontWeight="700"
                      fontSize={{ xs: "12px", md: "14px" }}
                      textAlign="center"
                      color="#FFFFFF"
                      fontFamily="Montserrat"
                    >
                      Level 4 - 1%
                    </Typography>
                    <Typography
                      fontWeight="700"
                      fontSize={{ xs: "12px", md: "14px" }}
                      textAlign="center"
                      color="#FFFFFF"
                      fontFamily="Montserrat"
                    >
                      Level 5 - 1%
                    </Typography>
                  </Box>
                )}

                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  boxShadow="#ffffff 0px 0px 7px 1px"
                  px={2}
                  mt={1}
                >
                  <Typography
                    fontWeight="700"
                    fontSize="13px"
                    display="flex"
                    alignItems="center"
                    textTransform="uppercase"
                    color="#DFDFDF"
                    fontFamily="Montserrat"
                    marginTop="15px"
                  >
                    Level{" "}
                    <span
                      style={{ fontFamily: "Montserrat", marginLeft: "5px" }}
                    >
                      3
                    </span>
                  </Typography>

                  <Typography
                    fontWeight="600"
                    fontSize={{ xs: "20px", md: "26px" }}
                    lineHeight="150%"
                    display="flex"
                    alignItems="center"
                    color="#FFFFFF"
                    fontFamily="Montserrat"
                  >
                    {level3Users}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                height="100%"
                position="relative"
                display="flex"
                alignItems="center"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  boxShadow="#ffffff 0px 0px 7px 1px"
                  px={2}
                  mt={1}
                >
                  <Typography
                    fontWeight="700"
                    fontSize="13px"
                    display="flex"
                    alignItems="center"
                    textTransform="uppercase"
                    color="#DFDFDF"
                    fontFamily="Montserrat"
                    marginTop="15px"
                  >
                    Level{" "}
                    <span
                      style={{ fontFamily: "Montserrat", marginLeft: "5px" }}
                    >
                      4
                    </span>
                  </Typography>

                  <Typography
                    fontWeight="600"
                    fontSize={{ xs: "20px", md: "26px" }}
                    lineHeight="150%"
                    display="flex"
                    alignItems="center"
                    color="#FFFFFF"
                    fontFamily="Montserrat"
                  >
                    {level4Users}
                  </Typography>
                </Box>
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  boxShadow="#ffffff 0px 0px 7px 1px"
                  px={2}
                  mt={1}
                >
                  <Typography
                    fontWeight="700"
                    fontSize="13px"
                    display="flex"
                    alignItems="center"
                    textTransform="uppercase"
                    color="#DFDFDF"
                    fontFamily="Montserrat"
                    marginTop="15px"
                  >
                    Level{" "}
                    <span
                      style={{ fontFamily: "Montserrat", marginLeft: "5px" }}
                    >
                      5
                    </span>
                  </Typography>

                  <Typography
                    fontWeight="600"
                    fontSize={{ xs: "20px", md: "26px" }}
                    lineHeight="150%"
                    display="flex"
                    alignItems="center"
                    color="#FFFFFF"
                    fontFamily="Montserrat"
                  >
                    {level5Users}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box
            fontFamily="Montserrat"
            mt={2}
            justifyContent="center"
            display="flex"
            alignItems="center"
            bgcolor="#000000"
            style={{
              opacity: "50%",
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
            px={matches ? 1 : 3}
            py={1}
          >
            <Box
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
              fontSize={matches ? "10px" : "15px"}
            >
              {account
                ? window.location.origin +
                  "/?ref=" +
                  account.slice(0, 4) +
                  "..." +
                  account.slice(-5)
                : "Connect your wallet"}
            </Box>
            <CopyToClipboard
              text={window.location.origin + "/?ref=" + account}
              onCopy={() => {
                setAlertState({
                  open: true,
                  message: `Refferal Link Copied.`,
                  severity: "success",
                });
              }}
            >
              <ContentCopyIcon
                sx={{ color: "#ffffff", cursor: "pointer", ml: "10px" }}
              />
            </CopyToClipboard>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
